import React from 'react'
import { connect as connectFela } from "react-fela"

import fluidValues from "utilities/fluidValues"
import UnderlinedTitle from "components/UnderlinedTitle"
import P from "components/typography/P"
import Value from "components/Value"
import Container from "components/container"

/**
 * Values Block, List of values plus a title and an intro
 *
 * @param {array} values Values data
 * @param {string} title Title of the block
 * @param {string} intro Intro text
 * @param {object} styles Fela styles
 * @param {object} rules Fela rules
 * @param {array} children Children components, usually just text
 *className={styles.image}
 * @return {jsx}
 */
const values = ({rules, values, title, intro, styles }) => (
  <div className={styles.styles}>
    <Container withContentArea3>
      <div className={styles.titleMargin}>
        <UnderlinedTitle extend={{styles: rules.overrideTitle}}>{title}</UnderlinedTitle>
      </div>
      {intro && <P version='xlarge' neo extend={{styles: rules.intro}}>{intro}</P>}
    </Container>
    <div className={styles.valuesContainer}>
      {values && values.map((value, index) => {
        return <Value extend={{container: rules.value}} odd={(index + 1) % 2} key={'values-' + index} image={value.image} title={value.title} content={value.content} />
      })}
    </div>
  </div>
)

/* Styles */
const styles = () => ({
  styles: {
    width: '100%',
  },
  overrideTitle: {
    marginBottom: '0 !important',
    display: 'block',
  },
  titleMargin: {
    ...fluidValues({
      marginBottom: [48, 90],
    })
  },
  intro: {
    ...fluidValues({
      marginBottom: [48, 90],
      maxWidth: [232, 733]
    })
  },
  valuesContainer: {
    maxWidth: 1042,
    margin: '0 auto',
  },
  value: {
    extend: {
      ...fluidValues({marginBottom: [60, 120]}),
    },
  }
})

/* Component */
const Values = connectFela(styles)(values)
export default Values
