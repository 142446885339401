import React, { useState, useRef } from 'react'
import { connect as connectFela } from "react-fela"
import { useSpring, animated, config} from 'react-spring'

import Img from 'components/Img'
import NextButton from "components/NextButton"
import PrevButton from "components/PrevButton"
import fluidDimensions from "utilities/fluidDimensions"
import fluidValues from "utilities/fluidValues"
import UnderlinedTitle from "components/UnderlinedTitle"
import Container from "components/container"

/**
 * Image slider
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *className={styles.image}
 * @return {jsx}
 */
const Slider = ({title, images, styles, rules}) => {
  const scrollContainer = useRef()
  const [x, setX] = useSpring(() => ({
    immediate: false,
    x: 0,
    config: config.slow,
  }));
  const [last, setLast] = useState(false)
  const [first, setFirst] = useState(false)

  const slideBack = () => {
    const el = scrollContainer.current
    let len = null
    if (el.scrollLeft < el.clientWidth) {
      len = 0
    } else {
      len = (el.scrollLeft - el.clientWidth)
    }

    setX({ x: len });
    setFirst(el.scrollLeft < el.clientWidth)
    setLast(false)
  }

  const slide = () => {
    const el = scrollContainer.current
    let len = null

    if ((el.scrollWidth - el.scrollLeft) <= 2*el.clientWidth) {
      len = el.scrollLeft + (el.scrollWidth - el.clientWidth - el.scrollLeft)
    } else {
      len = el.scrollLeft + el.clientWidth
    }

    setX({ x: len});
    setLast((el.scrollWidth - (el.scrollLeft + el.clientWidth)) < el.clientWidth)
    setFirst(false)
  }

  return (
    <section>
      {title && <Container withContentArea3>
                  <div className={styles.titleMargin}>
                    <UnderlinedTitle extend={{styles: rules.overrideTitle}}>{title}</UnderlinedTitle>
                  </div>
                </Container>}
      <div className={styles.buttonsContainer}>
        {first === false && <PrevButton onClick={slideBack} extend={{styles: rules.prevButton}} />}
        {!last && <NextButton onClick={slide} extend={{styles: rules.nextButton}} />}
      </div>
      <div className={styles.hideScrollbars}>
        <animated.div ref={scrollContainer} className={styles.container} scrollLeft={x.x}>
          {images && images.map(({credits: caption, asset: image}, index) =>
            <div key={index + "C"} className={styles.imageContainer} >
              <Img
                odd={(index !== 0 && index % 1 === 0 ? true : false)}
                style={{marginTop: (index % 2 === 0 ? 'unset' : 'auto')}}
                caption={caption}
                className={styles.image}
                key={index}
                alt={caption}
                image={image.gatsbyImageData} />
            </div>
          )}
        </animated.div>
      </div>
    </section>
  )
}

/* Styles */
const styles = () => ({
  overrideTitle: {
    marginBottom: '0 !important',
    display: 'block',
  },
  titleMargin: {
    ...fluidValues({
      marginBottom: [60, 120],
    })
  },
  hideScrollbars: {
    ...fluidDimensions({height: [308, 868]}),
    overflow: 'hidden',
    paddingBottom: 20,
  },
  container: {
    ...fluidValues({marginLeft: [-12, -16], marginRight: [-12, -16]}),
    display: 'flex',
    height: '106%',
    justifyContent: 'space-between',
    overflowX: 'scroll',
    maskImage: 'linear-gradient(to right, transparent, black 20px, black 90%, transparent)',
  },
  image: {
    width: '100%',
    height: '85%',
  },
  imageContainer: {
    ...fluidValues({marginLeft: [12, 16], marginRight: [12, 16]}),
    ...fluidValues({minWidth: [187, 515]}),
    display: 'flex',
    flex: '1 1 auto',
    height: 'auto',
    minHeight: 247,
    width: '100%',
    position: 'relative',

    phoneOnly: {
      flex: '0 0 187px',
    },
  },
  buttonsContainer: {
    display: 'flex',
  },
  prevButton: {
    display: 'block',
    marginRight: 24,
    marginBottom: 32,
    zIndex: 2,

    phoneOnly: {
      display: 'none',
    }
  },
  nextButton: {
    display: 'block',
    marginBottom: 32,
    zIndex: 2,

    phoneOnly: {
      display: 'none',
    }
  },
})

/* Component */
export default connectFela(styles)(Slider)
